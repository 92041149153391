* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "RedHatDisplay-Medium";
    scroll-behavior: smooth !important;
}
.href {
    text-decoration: none;
}

@font-face {
    font-family: "RedHatDisplay-Black";
    src: url("./assets/redHat/RedHatDisplay-Black.ttf") format("truetype");
}
@font-face {
    font-family: "RedHatDisplay-BlackItalic";
    src: url("./assets/redHat/RedHatDisplay-BlackItalic.ttf") format("truetype");
}
@font-face {
    font-family: "RedHatDisplay-Bold";
    src: url("./assets/redHat/RedHatDisplay-Bold.ttf") format("truetype");
}
@font-face {
    font-family: "RedHatDisplay-BoldItalic";
    src: url("./assets/redHat/RedHatDisplay-BoldItalic.ttf") format("truetype");
}
@font-face {
    font-family: "RedHatDisplay-ExtraBold";
    src: url("./assets/redHat/RedHatDisplay-ExtraBold.ttf") format("truetype");
}
@font-face {
    font-family: "RedHatDisplay-ExtraBoldItalic";
    src: url("./assets/redHat/RedHatDisplay-ExtraBoldItalic.ttf")
        format("truetype");
}
@font-face {
    font-family: "RedHatDisplay-Italic";
    src: url("./assets/redHat/RedHatDisplay-Italic.ttf") format("truetype");
}
@font-face {
    font-family: "RedHatDisplay-Light";
    src: url("./assets/redHat/RedHatDisplay-Light.ttf") format("truetype");
}
@font-face {
    font-family: "RedHatDisplay-LightItalic";
    src: url("./assets/redHat/RedHatDisplay-LightItalic.ttf") format("truetype");
}

@font-face {
    font-family: "RedHatDisplay-Medium";
    src: url("./assets/redHat/RedHatDisplay-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "RedHatDisplay-MediumItalic";
    src: url("./assets/redHat/RedHatDisplay-MediumItalic.ttf")
        format("truetype");
}

@font-face {
    font-family: "RedHatDisplay-Regular";
    src: url("./assets/redHat/RedHatDisplay-Regular.ttf") format("truetype");
}
@font-face {
    font-family: "RedHatDisplay-SemiBold";
    src: url("./assets/redHat/RedHatDisplay-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "RedHatDisplay-SemiBoldItalic";
    src: url("./assets/redHat/RedHatDisplay-SemiBoldItalic.ttf")
        format("truetype");
}

.PhoneInputInput {
    color: #000;
    padding: 8px;
    font-size: 18px;
    background-color: white;
    border: none;
    outline: none;
    font-family: "FuturaPTDemi";
    border-radius: 5px !important;
}

.PhoneInputCountrySelect {
    display: none !important;
}

.PhoneInputCountryIcon--border {
    border: none !important;
    border-width: 0 !important;
    background-color: transparent !important;
    box-shadow: none !important;
}

.PhoneInputCountryIcon {
    width: 40px !important;
    height: 25px !important;
    border: none !important;
}
.PhoneInputCountryIconImg {
    color: #fff !important;
    width: 100%;
    height: 100%;
}
.PhoneInputCountrySelectArrow {
    display: none !important;
}
